<script>
	import './styles.css';
	import '../app.pcss';
	import Navbar from '$lib/components/custom/Navbar.svelte';
	import { ModeWatcher } from 'mode-watcher';
	import { Toaster } from "$lib/components/ui/sonner";
	import { navigating, page } from '$app/stores';
	import { L3Columns } from '$lib/components/custom/layouts';
	import { RotatingDots} from 'svelte-loaders'
	import { fly } from 'svelte/transition';
</script>



<ModeWatcher track={false}  defaultMode={"light"} />
<Toaster richColors position="top-right" />
<div class="app container mx-auto max-w-4xl px-4 pt-2">
	<nav>
		{#if $page.data.user}
			<Navbar />
		{/if}
	</nav>

	{#if $page.data.user}
	<L3Columns>
		<div slot="right" class="flex flex-row justify-center align-middle p-2 mt-2 border rounded shadow">
			{$page.data.user.first_name} {$page.data.user.last_name}
		</div>
	</L3Columns>
	{/if}

	<div class="p-6 mt-8 border rounded shadow">
		{#if $navigating}
			<div class="flex flex-row justify-center align-middle" in:fly>
				<RotatingDots visible={true} height={60} width={60} color="#000000" dotRadius={4} clockWise={true}/>
			</div>
		{:else}
			<div in:fly>
				<slot></slot>
			</div>
		{/if}
	</div>

	<footer>
	</footer>
</div>
