<script lang="ts">
	import { goto } from "$app/navigation";
	import * as Menubar from "$lib/components/ui/menubar";
	import { ArchiveBoxArrowDown, ArrowLeftStartOnRectangle, DocumentMagnifyingGlass, DocumentPlus, DocumentText, User, UserPlus, Users } from "svelte-heros-v2";
	import { Button } from "$lib/components/ui/button";
</script>

  <Menubar.Root>
	<Menubar.Menu>
	  <Menubar.Trigger><User /> <span class="ml-2 mr-2"> პაციენტი</span></Menubar.Trigger>
	  <Menubar.Content>
		<Menubar.Item on:click={() => goto('/patient')}>
		  <UserPlus /> <span class="ml-2 mr-2"> დამატება</span>
		</Menubar.Item>
		<Menubar.Item on:click={() => goto('/patients')}>
		  <Users /> <span class="ml-2 mr-2"> ძებნა</span>
		</Menubar.Item>
	  </Menubar.Content>
	</Menubar.Menu>

	<Menubar.Menu>
	  <Menubar.Trigger><DocumentText /> <span class="ml-2 mr-2"> პდ</span></Menubar.Trigger>
	  <Menubar.Content>
		<Menubar.Item on:click={() => goto('/pd')}>
		  <DocumentPlus /> <span class="ml-2 mr-2"> დამატება</span>
		</Menubar.Item>
		<Menubar.Item on:click={() => goto('/pds')}>
		  <DocumentMagnifyingGlass /> <span class="ml-2 mr-2"> სია</span>
		</Menubar.Item>
	  </Menubar.Content>
	</Menubar.Menu>

	<Menubar.Menu>
		<Menubar.Trigger><DocumentText /> <span class="ml-2 mr-2"> ინფექციური</span></Menubar.Trigger>
		<Menubar.Content>
			<Menubar.Item on:click={() => goto('/infectious')}>
				<DocumentPlus /> <span class="ml-2 mr-2"> დამატება</span>
			</Menubar.Item>
			<Menubar.Item on:click={() => goto('/infectiouses')}>
				<DocumentMagnifyingGlass /> <span class="ml-2 mr-2"> სია</span>
			</Menubar.Item>
		</Menubar.Content>
	</Menubar.Menu>

	<Menubar.Menu>
		<Menubar.Trigger><DocumentText /> <span class="ml-2 mr-2"> არაინფექციური</span></Menubar.Trigger>
		<Menubar.Content>
			<Menubar.Item on:click={() => goto('/noninfectious')}>
				<DocumentPlus /> <span class="ml-2 mr-2"> დამატება</span>
			</Menubar.Item>
			<Menubar.Item on:click={() => goto('/noninfectiouses')}>
				<DocumentMagnifyingGlass /> <span class="ml-2 mr-2"> სია</span>
			</Menubar.Item>
		</Menubar.Content>
	</Menubar.Menu>

	<Menubar.Menu>
	  <Menubar.Trigger><DocumentText /> <span class="ml-2 mr-2"> შეფასება</span></Menubar.Trigger>
	  <Menubar.Content>
		<Menubar.Item on:click={() => goto('/assessment')}>
		  <DocumentPlus /> <span class="ml-2 mr-2"> დამატება</span>
		</Menubar.Item>
		<Menubar.Item on:click={() => goto('/assessments')}>
		  <DocumentMagnifyingGlass /> <span class="ml-2 mr-2"> სია</span>
		</Menubar.Item>
	  </Menubar.Content>
	</Menubar.Menu>

	<Menubar.Menu>
		<Menubar.Item on:click={() => goto('/xlsx')}>
			<ArchiveBoxArrowDown /> <span class="ml-2 mr-2"> ექსელი</span>
		</Menubar.Item>
	</Menubar.Menu>

	<form method="POST" action="/logout">
		<Menubar.Menu>
			<Menubar.Item>
				<Button variant="secondary" type="submit"> <ArrowLeftStartOnRectangle /> <!--გამოსვლა--></Button>
			</Menubar.Item>
		</Menubar.Menu>
	</form>
  </Menubar.Root>  
 
	
